document.addEventListener('DOMContentLoaded', function () {
  const zipInput = document.getElementById('zip-input');

  const selection = {};

  zipInput.addEventListener('keydown', function (e) {
    selection.selectionStart = e.target.selectionStart;
    selection.selectionEnd = e.target.selectionEnd;
  });

  zipInput.addEventListener('input', function (e) {
    const originalValue = e.target.value;

    const newInput = originalValue.replace(/\D/g, '').slice(0, 5);

    if (newInput !== originalValue) {
      // Users enter the not supported characters
      // Restore the value and selection
      e.target.value = newInput;
      e.target.setSelectionRange(selection.selectionStart, selection.selectionEnd);
    }
  });
});
